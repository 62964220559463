function HistoryHolidays(){
    return(
        <div>
        <p>HistoryHolidays</p>
        <p>HistoryHolidays</p>
        <p>HistoryHolidays</p>
        <p>HistoryHolidays</p>
        </div>
    )
}

export default HistoryHolidays;